<template>
  <div>
    <div class="h4">I have posted about Liquifi conversion</div>

    <div class="text mt-3">Post supposed to be open until the end of promotion</div>
    <!-- <PostAboutUsLinks class="mt-3" /> -->

    <b-form-checkbox class="mt-3" v-model="selected" name="ready">
      Ready
    </b-form-checkbox>

    <b-button
        block
        variant="primary"
        class="button-flat mt-3"
        v-on:click="onNextStep"
        :disabled="!selected"
    >Next</b-button>
  </div>
</template>

<script>
export default {
  name: 'PostAboutStep',
  components: {
    // PostAboutUsLinks: () => import("@/view/pages/bind/preRegisterStepper/components/PostAboutUsLinks"),
  },

  data: function () {
    return {
      selected: false,
    };
  },

  methods: {
    onNextStep() {
      this.$emit('next');
    },
  }
};
</script>

<style scoped lang="scss">

</style>
